import { gql } from '@apollo/client';

export const typeDefs = gql`
  extend type Query {
    loggedIn: Boolean
    selectedSite: String
  }
`;

export const IS_LOGGED_IN = gql`
  query IsLoggedIn {
    loggedIn @client
  }
`;

export const SELECTED_SITE = gql`
  query GetSelectedSite {
    selectedSite @client
  }
`;
