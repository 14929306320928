import React from "react";
import styled from "styled-components";
import { SELECTED_SITE } from "api/client";
import { useQuery, useMutation } from "@apollo/client";
import {
  CURRENT_USER_DATA,
  UPLOAD_FILE,
  SITE_UPLOADED_FILES,
} from "api/server";
import { userHasPermission } from "lib";
import { Redirect } from "react-router-dom";
import routes from "routes";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import IconButton from "@material-ui/core/IconButton";
import Chip from "@material-ui/core/Chip";
import { first } from "lodash";
import { UploadFile, UploadFileVariables } from "api/__generated__/UploadFile";
import { UserData } from "api/__generated__/UserData";
import { GetSelectedSite } from "api/__generated__/GetSelectedSite";
import { UploadedFileList } from "./components";

export const CDNUpload = () => {
  const { data: siteData } = useQuery<GetSelectedSite>(SELECTED_SITE);
  const selectedSite = siteData?.selectedSite || "all";
  const { data: userData } = useQuery<UserData>(CURRENT_USER_DATA);

  const [uploadFile, { loading, data: uploadedFile }] = useMutation<
    UploadFile,
    UploadFileVariables
  >(UPLOAD_FILE, {
    refetchQueries: [
      {
        query: SITE_UPLOADED_FILES,
        variables: { site: selectedSite },
      },
    ],
    onCompleted: ({ uploadFile: { url } }) => {
      navigator.clipboard.writeText(url);
    },
  });

  if (!userData) return null;

  const canSee = userHasPermission(userData.self, {
    permission: "upload",
  });
  if (!canSee) return <Redirect to={routes.home} />;

  if (selectedSite === "all")
    return <p>Please select a site to upload files.</p>;

  const chipLabel = (() => {
    if (loading) return "Uploading...";
    if (uploadedFile) return "URL copied to clipboard!";
    return "Upload File...";
  })();

  return (
    <>
      <div>
        <IconButton component="label">
          <AttachFileIcon />
          <input
            hidden
            type="file"
            onChange={({ target: { files } }) => {
              const file = first(files);
              if (!file) return;

              uploadFile({ variables: { site: selectedSite, file } });
            }}
          />
        </IconButton>
        <SChip color="primary" label={chipLabel} />
      </div>
      <h2>Upload History</h2>
      <UploadedFileList site={selectedSite} />
    </>
  );
};

const SChip = styled(Chip)`
  margin-left: 10px;
`;
