/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum MemberAttributeKind {
  ADDRESS = "ADDRESS",
  EMAIL = "EMAIL",
  PHONE = "PHONE",
}

export interface FullMemberInput {
  id: string;
  site: string;
  firstName?: string | null;
  lastName?: string | null;
  password: string;
  attributes?: MemberAttributeInput[] | null;
  showInDirectory: boolean;
}

export interface MemberAttributeInput {
  kind: MemberAttributeKind;
  value: string;
}

export interface PartialMemberInput {
  id: string;
  site: string;
  firstName?: string | null;
  lastName?: string | null;
  password?: string | null;
  attributes?: MemberAttributeInput[] | null;
  showInDirectory?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
