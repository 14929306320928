import React from 'react';
import { SELECTED_SITE } from 'api/client';
import { useQuery } from '@apollo/client';
import { CURRENT_USER_DATA } from 'api/server';
import { userHasPermission } from 'lib';
import { Redirect } from 'react-router-dom';
import routes from 'routes';
import { MemberList } from './components';
import { GetSelectedSite } from 'api/__generated__/GetSelectedSite';
import { UserData } from 'api/__generated__/UserData';

export const Members = () => {
  const { data: siteData } = useQuery<GetSelectedSite>(SELECTED_SITE);
  const selectedSite = siteData?.selectedSite || 'all';
  const { data: userData } = useQuery<UserData>(CURRENT_USER_DATA);

  if (!userData) return null;

  const canSee = userHasPermission(userData.self, {
    permission: 'manageMember',
  });
  if (!canSee) return <Redirect to={routes.home} />;

  if (selectedSite === 'all')
    return <p>Please select a site to view Members.</p>;

  return <MemberList site={selectedSite} />;
};
