import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { useQuery } from "@apollo/client";
import CircularProgress from "@material-ui/core/CircularProgress";
import { map } from "lodash";
import { Button } from "common";
import { SITE_UPLOADED_FILES } from "api/server";
import {
  UploadedFiles,
  UploadedFilesVariables,
} from "api/__generated__/UploadedFiles";
import { formatDistanceToNow } from "date-fns";

type Props = {
  site: string;
};

export const UploadedFileList = ({ site }: Props) => {
  const { data, loading } = useQuery<UploadedFiles, UploadedFilesVariables>(
    SITE_UPLOADED_FILES,
    {
      variables: { site },
    }
  );

  if (!data?.self?.site?.uploadedFiles || loading) return <CircularProgress />;

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>File Name</TableCell>
              <TableCell>Uploaded At</TableCell>
              <TableCell>Copy URL</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {map(
              data.self.site.uploadedFiles,
              ({ id, fileName, url, createdAt }) => (
                <TableRow key={id}>
                  <TableCell>
                    <a href={url} target="_blank" rel="noopener noreferrer">
                      {fileName}
                    </a>
                  </TableCell>
                  <TableCell>
                    {formatDistanceToNow(new Date(createdAt))} ago
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        navigator.clipboard.writeText(url);
                      }}
                    >
                      Copy URL to Clipboard
                    </Button>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
