import React from 'react';
import { Button } from 'common';
import { useQuery } from '@apollo/client';
import { CURRENT_USER_DATA } from 'api/server';
import { userHasPermission } from 'lib';
import { Container } from './styled';
import { useHistory } from 'react-router-dom';
import routes from 'routes';
import { UserData } from 'api/__generated__/UserData';

export const Upload = () => {
  const history = useHistory();
  const { data: userData } = useQuery<UserData>(CURRENT_USER_DATA);

  if (!userData) return null;

  const canSee = userHasPermission(userData.self, {
    permission: 'upload',
  });
  if (!canSee) return null;

  return (
    <Container>
      <Button onClick={() => history.push(routes.cdnUpload)}>
        Upload Files to CDN
      </Button>
    </Container>
  );
};
