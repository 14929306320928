import React, { useState } from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useMutation, useApolloClient } from '@apollo/client';
import { IS_LOGGED_IN } from 'api/client';
import { LOGIN } from 'api/server';
import {
  Login as LoginMutation,
  LoginVariables,
} from 'api/__generated__/Login';

export const Login = () => {
  const client = useApolloClient();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [login, { data, loading, error }] = useMutation<
    LoginMutation,
    LoginVariables
  >(LOGIN, {
    onCompleted: async ({ login }) => {
      if (!login?.success || !login.token) return;
      localStorage.setItem('FLUID_GOLF_TOKEN', login.token);
      client.cache.reset();
      client.writeQuery({ query: IS_LOGGED_IN, data: { loggedIn: true } });
    },
  });

  const disabled = !(username && password) || loading;

  const submit = () => login({ variables: { username, password } });

  return (
    <OuterContainer>
      <FormContainer>
        <Header>Fluid Golf</Header>
        <div>
          <TextField
            value={username}
            onChange={({ target: { value } }) => setUsername(value)}
            variant="outlined"
            label="Username"
            margin="normal"
            fullWidth
          />
        </div>
        <div>
          <TextField
            value={password}
            onChange={({ target: { value } }) => setPassword(value)}
            variant="outlined"
            label="Password"
            type="Password"
            margin="normal"
            fullWidth
          />
        </div>
        {(error || data?.login?.success === false) && (
          <p>{data?.login?.message || 'Login failed. Please try again.'}</p>
        )}
        <ButtonContainer>
          <Button
            variant="contained"
            color="primary"
            disabled={disabled}
            onClick={submit}
          >
            Login
          </Button>
        </ButtonContainer>
      </FormContainer>
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;
const FormContainer = styled.div`
  width: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const Header = styled.h1`
  margin-bottom: 0px;
`;
const ButtonContainer = styled.div`
  margin: 10px 0px;
`;
