export const userHasPermission = (
  {
    sites,
    permissions,
  }: {
    sites: Array<{ slug: string }>;
    permissions: string[];
  },
  {
    site,
    permission,
  }: {
    site?: string;
    permission?: string;
  }
) => {
  if (permissions.includes('admin')) return true;
  if (site === 'all') return false;

  if (permission && !permissions.includes(permission)) return false;
  if (site && !sites.find(({ slug }) => slug === site)) return false;

  return true;
};
