import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import routes from 'routes';
import {
  Login,
  Layout,
  Home,
  Members,
  EditMember,
  AddMember,
  CDNUpload,
} from 'screens';
import { IS_LOGGED_IN } from 'api/client';
import { useQuery } from '@apollo/client';

function App() {
  const { data } = useQuery(IS_LOGGED_IN);

  return (
    <Router>
      {data?.loggedIn ? (
        <Layout>
          <Switch>
            <Route exact path={routes.home} component={Home} />
            <Route exact path={routes.members} component={Members} />
            <Route exact path={routes.addMember} component={AddMember} />
            <Route exact path={routes.editMember} component={EditMember} />
            <Route exact path={routes.cdnUpload} component={CDNUpload} />

            <Route>
              <Redirect to={routes.home} />
            </Route>
          </Switch>
        </Layout>
      ) : (
        <Login />
      )}
    </Router>
  );
}

export default App;
