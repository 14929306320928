import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useMutation, useQuery } from "@apollo/client";
import { useRouteMatch, useHistory } from "react-router-dom";
import routes from "routes";
import {
  TextField,
  CircularProgress,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { Button } from "common";
import { Container, SCircularProgress } from "../Home/components/styled";
import { DELETE_MEMBER, EDIT_MEMBER, GET_MEMBER } from "api/server";
import {
  EditMember as EditMemberMutation,
  EditMemberVariables,
} from "api/__generated__/EditMember";
import {
  DeleteMember,
  DeleteMemberVariables,
} from "api/__generated__/DeleteMember";
import { GetMember, GetMemberVariables } from "api/__generated__/GetMember";
import { MemberAttributeInput, MemberAttributeKind } from "globalTypes";
import { map, cloneDeep, some, pullAt } from "lodash";

export const EditMember = () => {
  const {
    params: { site, id },
  } = useRouteMatch<{ site: string; id: string }>(routes.editMember)!;
  const { push } = useHistory();

  const [editMember, { loading: editLoading }] = useMutation<
    EditMemberMutation,
    EditMemberVariables
  >(EDIT_MEMBER, {
    refetchQueries: ["GetMember", "Members"],
  });
  const [deleteMember, { loading: deleteLoading }] = useMutation<
    DeleteMember,
    DeleteMemberVariables
  >(DELETE_MEMBER, {
    refetchQueries: ["Members"],
    onCompleted: () => push(routes.members),
  });
  const { data, loading } = useQuery<GetMember, GetMemberVariables>(
    GET_MEMBER,
    { variables: { site, id } }
  );
  const member = data?.self?.site?.member;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [attributes, setAttributes] = useState<MemberAttributeInput[]>([]);
  const [showInDirectory, setShowInDirectory] = useState(false);

  useEffect(() => {
    if (!member) return;
    setFirstName(member.firstName || "");
    setLastName(member.lastName || "");
    setAttributes(
      member.attributes?.map(({ kind, value }) => ({
        kind,
        value,
      })) || []
    );
    setShowInDirectory(member.showInDirectory || false);
  }, [member]);

  const submit = () => {
    editMember({
      variables: {
        member: {
          firstName,
          lastName,
          site,
          id,
          password,
          attributes,
          showInDirectory,
        },
      },
    });

    setPassword("");
  };

  const saveDisabled = some(attributes, (attribute) => attribute.value === "");

  const submitDelete = () =>
    deleteMember({
      variables: { site, id },
    });

  if (loading || !member) return <CircularProgress />;

  return (
    <div>
      <h1>Member {id}</h1>
      <FormContainer>
        <TextField
          value={firstName}
          onChange={({ target: { value } }) => setFirstName(value)}
          variant="outlined"
          label="First Name"
          disabled={editLoading}
        />
        <TextField
          value={lastName}
          onChange={({ target: { value } }) => setLastName(value)}
          variant="outlined"
          label="Last Name"
          disabled={editLoading}
        />
        <TextField
          value={password}
          onChange={({ target: { value } }) => setPassword(value)}
          variant="outlined"
          label="Password (only if changing)"
          type="password"
          disabled={editLoading}
        />
      </FormContainer>

      <div>
        <h2>Contact Information</h2>
        {map(attributes, (attribute, attrId) => {
          return (
            <ContactRow key={attrId}>
              <ContactItem>
                <Select
                  variant="outlined"
                  value={attribute.kind}
                  disabled={editLoading}
                  onChange={({ target: { value } }) => {
                    const newAttributes = cloneDeep(attributes);
                    newAttributes[attrId] = {
                      ...attribute,
                      kind: value as any,
                    };

                    setAttributes(newAttributes);
                  }}
                >
                  <MenuItem value={MemberAttributeKind.PHONE}>Phone</MenuItem>
                  <MenuItem value={MemberAttributeKind.EMAIL}>Email</MenuItem>
                  <MenuItem value={MemberAttributeKind.ADDRESS}>
                    Address
                  </MenuItem>
                </Select>
              </ContactItem>
              <ContactItem>
                <TextField
                  variant="outlined"
                  multiline
                  value={attribute.value}
                  disabled={editLoading}
                  onChange={({ target: { value } }) => {
                    const newAttributes = cloneDeep(attributes);
                    newAttributes[attrId] = { ...attribute, value };

                    setAttributes(newAttributes);
                  }}
                  placeholder="Contact Info"
                />
              </ContactItem>
              <ContactItem>
                <Button
                  color="secondary"
                  onClick={() => {
                    const newAttributes = cloneDeep(attributes);
                    pullAt(newAttributes, [attrId]);
                    setAttributes(newAttributes);
                  }}
                  disabled={editLoading}
                >
                  Remove
                </Button>
              </ContactItem>
            </ContactRow>
          );
        })}
        <ContactRow>
          <Button
            onClick={() => {
              setAttributes([
                ...attributes,
                { kind: MemberAttributeKind.PHONE, value: "" },
              ]);
            }}
            disabled={editLoading}
          >
            Add contact method
          </Button>
        </ContactRow>
        <ContactRow>
          <FormControlLabel
            control={
              <Checkbox
                checked={showInDirectory}
                onChange={({ target: { checked } }) =>
                  setShowInDirectory(checked)
                }
              />
            }
            label="Display contact information in Member Directory"
            disabled={editLoading}
          />
        </ContactRow>
      </div>

      <Container>
        <Button onClick={submit} disabled={editLoading || saveDisabled}>
          Save
        </Button>
        {editLoading && <SCircularProgress size={20} />}
      </Container>
      <Container>
        <Button
          onClick={submitDelete}
          disabled={deleteLoading}
          color="secondary"
        >
          Delete
        </Button>
      </Container>
    </div>
  );
};

const FormContainer = styled.div`
  max-width: 600px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;
`;
const ContactRow = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const ContactItem = styled.div`
  margin-right: 10px;
`;
