import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Select from '@material-ui/core/Select';
import { useQuery, useApolloClient } from '@apollo/client';
import { SELECTED_SITE, IS_LOGGED_IN } from 'api/client';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { CURRENT_USER_DATA } from 'api/server';
import { map, sortBy } from 'lodash';
import { Link } from 'react-router-dom';
import routes from 'routes';
import { GetSelectedSite } from 'api/__generated__/GetSelectedSite';
import { UserData } from 'api/__generated__/UserData';

type Props = {
  children: ReactNode;
};

export const Layout = ({ children }: Props) => {
  const client = useApolloClient();
  const selectedSiteQuery = useQuery<GetSelectedSite>(SELECTED_SITE);
  const { data: userData } = useQuery<UserData>(CURRENT_USER_DATA);

  const logout = async () => {
    client.cache.reset();

    client.writeQuery({
      query: IS_LOGGED_IN,
      data: { loggedIn: false },
    });

    localStorage.removeItem('FLUID_GOLF_TOKEN');
  };

  return (
    <Container>
      <Header>
        <LeftHeader>
          <Title to={routes.home}>
            <h1>Fluid Golf</h1>
          </Title>
          <FormControl variant="outlined">
            <InputLabel>Site</InputLabel>
            <SSelect
              value={selectedSiteQuery?.data?.selectedSite || 'all'}
              onChange={({ target: { value } }) => {
                client.writeQuery({
                  query: SELECTED_SITE,
                  data: { selectedSite: value },
                });
              }}
              label="Site"
              variant="outlined"
            >
              <MenuItem value="all">Global</MenuItem>
              {userData &&
                map(
                  sortBy(userData.self.sites, 'prettyPrinted'),
                  ({ slug, prettyPrinted }) => (
                    <MenuItem key={slug} value={slug}>
                      {prettyPrinted}
                    </MenuItem>
                  )
                )}
            </SSelect>
          </FormControl>
        </LeftHeader>

        <Button variant="outlined" onClick={logout}>
          Logout
        </Button>
      </Header>
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100px 1fr;
`;
const Header = styled.div`
  border-bottom: 1px solid gray;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`;
const LeftHeader = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-right: 50px;
  }
`;
const SSelect = styled(Select)`
  width: 400px;
`;
const Title = styled(Link)`
  color: black;
  text-decoration: none;
`;
const ChildrenContainer = styled.div`
  padding: 10px 20px 10px;
  overflow: auto;
`;
