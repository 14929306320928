import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import App from './App';
import { createGlobalStyle } from 'styled-components';
import { IS_LOGGED_IN } from 'api/client';
import { setContext } from '@apollo/link-context';
import { createUploadLink } from 'apollo-upload-client';

const httpLink = createUploadLink({
  uri: 'https://api.fluid.golf/graphql',
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('FLUID_GOLF_TOKEN');

  if (!token) return {};

  return {
    headers: {
      ...headers,
      'X-User-Token': token,
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink as any),
});

client.writeQuery({
  query: IS_LOGGED_IN,
  data: { loggedIn: Boolean(localStorage.getItem('FLUID_GOLF_TOKEN')) },
});

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }
`;

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
