import React from "react";
import { CURRENT_USER_DATA } from "api/server";
import { useQuery } from "@apollo/client";
import { Members, Upload } from "./components";
import { Button } from "common";
import CircularProgress from "@material-ui/core/CircularProgress";
import { UserData } from "api/__generated__/UserData";

export const Home = () => {
  const { data: userData, loading } = useQuery<UserData>(CURRENT_USER_DATA);

  if (loading || !userData?.self) return <CircularProgress />;

  return (
    <>
      <h2>Hello, {userData.self.name}!</h2>

      <Members />
      <Upload />

      <Button
        onClick={() => window.location.assign("https://prismic.io/dashboard/")}
      >
        Website Content
      </Button>
    </>
  );
};
