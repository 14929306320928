import React, { useState } from "react";
import styled from "styled-components";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import { useQuery } from "@apollo/client";
import CircularProgress from "@material-ui/core/CircularProgress";
import { map, filter } from "lodash";
import { Button } from "common";
import { useHistory } from "react-router-dom";
import routes from "routes";
import { SITE_MEMBERS } from "api/server";
import { Members, MembersVariables } from "api/__generated__/Members";

type Props = {
  site: string;
};

export const MemberList = ({ site }: Props) => {
  const { push } = useHistory();
  const { data, loading } = useQuery<Members, MembersVariables>(SITE_MEMBERS, {
    variables: { site },
  });
  const [idSearch, setIdSearch] = useState("");

  if (!data?.self?.site?.members || loading) return <CircularProgress />;

  return (
    <Paper>
      <Header>
        <LeftHeader>
          <h2>Members</h2>
          <Button onClick={() => push(routes.addMember)}>Add</Button>
        </LeftHeader>
        <STextField
          label="Search by ID"
          value={idSearch}
          onChange={({ target: { value } }) => setIdSearch(value)}
        />
      </Header>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Member ID</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Edit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {map(
              filter(data.self.site.members, ({ id }) =>
                id.toLowerCase().includes(idSearch.toLowerCase())
              ),
              ({ id, firstName, lastName }) => (
                <ListItem
                  key={id}
                  id={id}
                  firstName={firstName}
                  lastName={lastName}
                  site={site}
                />
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

const ListItem = React.memo(
  ({
    id,
    firstName,
    lastName,
    site,
  }: {
    id: string;
    firstName: string | null;
    lastName: string | null;
    site: string;
  }) => {
    const { push } = useHistory();

    return (
      <TableRow>
        <TableCell>{id}</TableCell>
        <TableCell>{firstName}</TableCell>
        <TableCell>{lastName}</TableCell>
        <TableCell>
          <Button
            onClick={() =>
              push(
                `/members/edit/${encodeURIComponent(site)}/${encodeURIComponent(
                  id
                )}`
              )
            }
          >
            Edit
          </Button>
        </TableCell>
      </TableRow>
    );
  }
);

const Header = styled.div`
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const LeftHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h2 {
    margin: 0px;
    margin-right: 20px;
  }
`;
const STextField = styled(TextField)`
  width: 300px;
`;
