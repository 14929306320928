import { gql } from "@apollo/client";

export const CURRENT_USER_DATA = gql`
  query UserData {
    self {
      name
      permissions
      sites {
        slug
        prettyPrinted
      }
    }
  }
`;

export const GET_MEMBER = gql`
  query GetMember($site: ID!, $id: ID!) {
    self {
      site(site: $site) {
        member(memberId: $id) {
          firstName
          lastName
          attributes {
            kind
            value
          }
          showInDirectory
        }
      }
    }
  }
`;

export const EDIT_MEMBER = gql`
  mutation EditMember($member: PartialMemberInput!) {
    editMember(member: $member)
  }
`;

export const ADD_MEMBER = gql`
  mutation AddMember($member: FullMemberInput!) {
    addMember(member: $member)
  }
`;

export const DELETE_MEMBER = gql`
  mutation DeleteMember($id: ID!, $site: ID!) {
    deleteMember(id: $id, site: $site)
  }
`;

export const UPLOAD_FILE = gql`
  mutation UploadFile($site: ID!, $file: Upload!) {
    uploadFile(site: $site, file: $file) {
      id
      fileName
      url
      createdAt
    }
  }
`;

export const LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      success
      token
      message
    }
  }
`;

export const SITE_MEMBERS = gql`
  query Members($site: ID!) {
    self {
      site(site: $site) {
        members {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const SITE_UPLOADED_FILES = gql`
  query UploadedFiles($site: ID!) {
    self {
      site(site: $site) {
        uploadedFiles {
          id
          fileName
          url
          createdAt
        }
      }
    }
  }
`;
