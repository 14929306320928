import React, { useState } from 'react';
import styled from 'styled-components';
import { SELECTED_SITE } from 'api/client';
import { useQuery, useMutation } from '@apollo/client';
import { CURRENT_USER_DATA, ADD_MEMBER, GET_MEMBER } from 'api/server';
import { GetSelectedSite } from 'api/__generated__/GetSelectedSite';
import { userHasPermission } from 'lib';
import { Redirect, useHistory } from 'react-router-dom';
import routes from 'routes';
import TextField from '@material-ui/core/TextField';
import { Container, SCircularProgress } from 'screens/Home/components/styled';
import { Button } from 'common';
import { UserData } from 'api/__generated__/UserData';

export const AddMember = () => {
  const { replace } = useHistory();
  const { data: siteData } = useQuery<GetSelectedSite>(SELECTED_SITE);
  const selectedSite = siteData?.selectedSite || 'all';
  const { data: userData } = useQuery<UserData>(CURRENT_USER_DATA);

  const [id, setId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');

  const [editMember, { loading: editLoading }] = useMutation(ADD_MEMBER, {
    refetchQueries: ['GetMember', 'Members'],
    onCompleted: () => replace(`/members/edit/${selectedSite}/${id}`),
  });

  const { data: existingMemberData, loading: existingLoading } = useQuery(
    GET_MEMBER,
    {
      variables: { site: selectedSite, id },
      skip: selectedSite === 'all' || id === '',
    }
  );
  const alreadyExists = Boolean(existingMemberData?.self?.site?.member);

  const submit = () => {
    editMember({
      variables: {
        member: { firstName, lastName, site: selectedSite, id, password, showInDirectory: false },
      },
    });

    setPassword('');
  };

  if (!userData) return null;

  const canSee = userHasPermission(userData.self, {
    permission: 'manageMember',
  });
  if (!canSee) return <Redirect to={routes.home} />;

  if (selectedSite === 'all')
    return <p>Please select a site to add a Member.</p>;

  const saveDisabled =
    !id || !password || alreadyExists || editLoading || existingLoading;

  return (
    <div>
      <h1>Add Member</h1>
      <FormContainer>
        <TextField
          value={id}
          onChange={({ target: { value } }) => setId(value)}
          variant="outlined"
          label="Member ID"
          disabled={editLoading}
          required
        />
        <LoadingContainer>
          {existingLoading && <SCircularProgress size={20} />}
          {!existingLoading && alreadyExists && (
            <p>Member with this ID already exists!</p>
          )}
        </LoadingContainer>
        <TextField
          value={firstName}
          onChange={({ target: { value } }) => setFirstName(value)}
          variant="outlined"
          label="First Name"
          disabled={editLoading}
        />
        <TextField
          value={lastName}
          onChange={({ target: { value } }) => setLastName(value)}
          variant="outlined"
          label="Last Name"
          disabled={editLoading}
        />
        <TextField
          value={password}
          onChange={({ target: { value } }) => setPassword(value)}
          variant="outlined"
          label="Password"
          type="password"
          disabled={editLoading}
          required
        />
      </FormContainer>
      <Container>
        <Button onClick={submit} disabled={saveDisabled}>
          Save
        </Button>
        {editLoading && <SCircularProgress size={20} />}
      </Container>
    </div>
  );
};

const FormContainer = styled.div`
  max-width: 600px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;
`;
const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
