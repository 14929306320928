import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import MuiButton, {
  ButtonProps as MuiButtonProps,
} from '@material-ui/core/Button';

export type ButtonProps = {
  onClick: () => void;
  disabled?: boolean | undefined;
  children?: ReactNode;
  className?: string;
  tooltipTitle?: TooltipProps['title'];
  tooltipPlacement?: TooltipProps['placement'];
  color?: MuiButtonProps['color'];
};

export const Button = ({
  onClick,
  disabled,
  tooltipTitle,
  children,
  className,
  tooltipPlacement,
  color,
}: ButtonProps) => (
  <Tooltip title={tooltipTitle || ''} placement={tooltipPlacement}>
    <Container>
      <MuiButton
        disabled={disabled}
        onClick={onClick}
        className={className}
        variant="outlined"
        color={color}
      >
        {children}
      </MuiButton>
      {disabled && <ButtonCover />}
    </Container>
  </Tooltip>
);

const Container = styled.span`
  position: relative;
`;
const ButtonCover = styled.div`
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
`;
